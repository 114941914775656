import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Container, Generic, Section } from "rbx"

import Header from "../components/header"
import Footer from "../components/footer"
import { Logo } from "../components/logo"

import "rbx/index.css"
import "../styles/main.scss"

const Layout = ({ children }) => {
  return (
    <Generic id="inner">
      <Header />
      <Section id="desc-section" style={{ padding: "2rem 1rem" }}>
        <Container className="has-text-centered" style={{ height: "100%" }}>
          <Link to="/">
            <Logo />
          </Link>
        </Container>
      </Section>
      <Section>
        <Container>{children}</Container>
      </Section>
      <Footer />
    </Generic>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
