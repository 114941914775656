import axios from "axios"
import React, { useState } from "react"
import { useCookies } from "react-cookie"
import { Formik, getIn } from "formik"
import { useStaticQuery, graphql } from "gatsby"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faExclamationTriangle,
  faHeading,
  faLink,
  faEnvelope,
  faUser,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import {
  Button,
  Checkbox,
  Content,
  Control,
  Field,
  Help,
  Icon,
  Input,
  Label,
  Textarea,
  Title,
} from "rbx"

import { CloseParticipateModalButton } from "src/components/modal"

// Input Control
const InputWidget = ({
  name,
  type,
  value,
  label,
  placeholder,
  icon,
  has_error,
  error,
  onChange,
  onBlur,
}) => (
  <Field>
    <Label>{label}</Label>
    <Control iconLeft iconRight>
      <Input
        id={name}
        name={name}
        type={type}
        value={value}
        className={`input ${has_error && "is-danger"}`}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
      <Icon size="small" align="left">
        <FontAwesomeIcon icon={icon} />
      </Icon>
      <Icon size="small" align="right">
        <FontAwesomeIcon icon={has_error ? faExclamationTriangle : faCheck} />
      </Icon>
    </Control>
    {error && <Help color="danger">{error}</Help>}
  </Field>
)

// Textarea Control
const TextareaWidget = ({
  name,
  type,
  rows,
  value,
  label,
  placeholder,
  icon,
  has_error,
  error,
  onChange,
  onBlur,
}) => (
  <Field>
    <Label>{label}</Label>
    <Control>
      <Textarea
        id={name}
        name={name}
        type={type}
        rows={rows}
        value={value}
        className={`input ${has_error && "is-danger"}`}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
    </Control>
    {error && <Help color="danger">{error}</Help>}
  </Field>
)

// Checkbox Control
const CheckboxWidget = ({
  name,
  value,
  label,
  has_error,
  error,
  onChange,
  onBlur,
}) => (
  <Field>
    <Control>
      <Checkbox
        id={name}
        name={name}
        defaultValue={value}
        onChange={onChange}
        onBlur={onBlur}
      />{" "}
      {label}
    </Control>
    {has_error && <Help color="danger">{error}</Help>}
  </Field>
)

const WorkForm = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          apiUrl
          worksApiEndpoint
          yandexMetrikaTrackID
          googleTagTrackID
        }
      }
    }
  `)
  const {
    apiUrl,
    worksApiEndpoint,
    yandexMetrikaTrackID,
    googleTagTrackID,
  } = data.site.siteMetadata

  const [submissionStatus, setSubmissionStatus] = useState(false)
  const [cookies, setCookie] = useCookies(["user", "works"])

  const get_initial_values = () => {
    const base_values = {
      creation_title: "",
      creation_author: "",
      url: "",
      participant_email: "",
      participant_name: "",
      participant_age: "",
      participant_country: "",
      participant_region: "",
      participant_locality: "",
      policy_confirmation: false,
    }
    const stored_values = "user" in cookies ? cookies.user : {}
    let initial_values = Object.assign(base_values, stored_values)
    if (initial_values["participant_age"] !== "")
      initial_values["participant_age"] = Number(
        initial_values["participant_age"]
      )

    return initial_values
  }

  const getError = (name, { touched, errors, status }) => {
    const fieldTouched = getIn(touched, name)
    const backendError = getIn(status, ["apiErrors", name])
    const clientError = getIn(errors, name)

    if (clientError && fieldTouched) {
      return clientError
    }
    if (backendError && !fieldTouched) {
      return backendError
    }
    return undefined
  }

  if (submissionStatus === 201)
    return (
      <Content className="has-text-centered">
        <Title>Ваш #ГолосПамяти принят!</Title>
        <p>
          Работа находится на рассмотрении и в ближайшее время (2-3 дня) мы
          пришлем Вам подтверждение участия по электронной почте.
        </p>
        <p>
          А пока, пожалуйста, проверьте ещё раз свою публикацию в соцсети и
          убедитесь, что:
        </p>
        <ul>
          <li>проставлены хэштеги #ГолосПамяти и #ЧитаемСтихиОвойне</li>
          <li>ролик доступен всем, а не только вам и вашим друзьям.</li>
        </ul>
        <p>Благодарим вас за поддержку инициативы!</p>
        <CloseParticipateModalButton
          color="danger"
          size="medium"
          className="has-text-centered"
        >
          Закрыть
        </CloseParticipateModalButton>
      </Content>
    )

  return (
    <Formik
      initialStatus={{ apiErrors: {} }}
      initialValues={get_initial_values()}
      validationSchema={Yup.object().shape({
        creation_title: Yup.string()
          .min(2, "Слишком короткое название стихотворения")
          .max(256, "Слишком длинное название стихотворения")
          .required("Необходимо указать название стихотворения"),
        creation_author: Yup.string()
          .min(2, "Слишком короткое имя")
          .max(64, "Слишком длинное имя")
          .required("Необходимо указать имя автора стихотворения"),
        creation_comment: Yup.string().max(4096, "Слишком длинный комментарий"),
        url: Yup.string()
          .url("Неправильная ссылка")
          .matches(
            /(^https:\/\/www\.youtube\.com\/watch\?v=|^https:\/\/youtu\.be\/|(?=.*video)^https:\/\/www\.facebook\.com\/|^https:\/\/vk\.com\/video|^https:\/\/ok\.ru\/video\/|^https:\/\/www\.instagram\.com\/p\/|^https:\/\/www\.instagram\.com\/tv\/|^https:\/\/twitter\.com\/i\/status\/)/,
            "Мы принимаем ссылки на ролики из ВКонтакте, Одноклассники, YouTube, Facebook, Instagram, Twitter"
          )
          .required("Необходимо указать ссылку на запись"),
        participant_email: Yup.string()
          .email("Неправильный email")
          .required("Необходимо указать email"),
        participant_name: Yup.string()
          .min(2, "Слишком короткое имя")
          .max(64, "Слишком длинное имя")
          .required("Необходимо указать ваше имя"),
        participant_age: Yup.number()
          .integer("Необходимо указать число полных лет")
          .positive("Необходимо указать возраст")
          .moreThan(0, "Что-то тут не так")
          .lessThan(150, "Что-то тут не так")
          .required("Необходимо указать возраст"),
        participant_country: Yup.string()
          .max(64, "Слишком длинное название страны")
          .required("Необходимо указать страну проживания"),
        participant_region: Yup.string()
          .max(64, "Слишком длинное название региона")
          .required("Необходимо указать регион проживания"),
        participant_locality: Yup.string()
          .max(64, "Слишком длинное название населённого пункта")
          .required("Необходимо указать свой населённый пункт"),
        policy_confirmation: Yup.boolean().oneOf(
          [true],
          "Необходимо ваше соглаcие с политикой конфиденциальности"
        ),
      })}
      onSubmit={(values, { resetForm, setSubmitting, validateForm }) => {
        validateForm()

        // Клонируем объект, исключая лишние поля
        let { policy_confirmation, ...request } = values

        // Сохраняем в куки сведения о пользователе
        let { creation_title, creation_author, url, ...user } = request
        setCookie("user", user, { path: "/", maxAge: 31536000 })

        // Готовим к добавлению в куки сведения о работе
        let {
          participant_email,
          participant_name,
          participant_age,
          participant_country,
          participant_region,
          participant_locality,
          ...work
        } = request

        const axios_instance = axios.create({
          baseURL: `${apiUrl}`,
        })

        axios_instance
          .post(`/${worksApiEndpoint}`, request)
          .then(function(response) {
            setSubmitting(false)
            if (response.status === 201) {
              work = Object.assign(work, response.data)
              setSubmissionStatus(response.status)
              ym(yandexMetrikaTrackID, "reachGoal", "send_form")
              gtag("event", "send_form")
            }
          })
          .catch(function(error) {
            setSubmitting(false)
            resetForm({
              values,
              status: {
                apiErrors: { url: "Работа по такой ссылке уже размещена" },
              },
            })
          })

        // Добавляем в куки сведения о работе
        let works = "works" in cookies ? cookies.works : []
        works.push(work)
        setCookie("works", works, { path: "/", maxAge: 31536000 })
      }}
    >
      {props => {
        const {
          values,
          touched,
          status,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props

        return (
          <>
            <Title className="has-text-centered">
              Добавьте свой #ГолосПамяти
            </Title>
            <p className="has-text-centered">
              Заполните, пожалуйста, анкету участника и информацию о содержании
              вашего ролика в соцсети
            </p>
            <form onSubmit={handleSubmit}>
              <InputWidget
                name="url"
                type="text"
                value={values.url}
                label="Ссылка на ролик в соцсети"
                placeholder="Например, https://www.youtube.com/watch?v=bdBuDg7mrT8"
                icon={faLink}
                has_error={touched.url && errors.url}
                error={getError("url", { touched, status, errors })}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputWidget
                name="creation_author"
                type="text"
                value={values.creation_author}
                label="Автор стихотворения"
                placeholder="Например, Василий Лебедев-Кумач"
                icon={faHeading}
                has_error={touched.creation_author && errors.creation_author}
                error={getError("creation_author", { touched, status, errors })}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputWidget
                name="creation_title"
                type="text"
                value={values.creation_title}
                label="Название стихотворения"
                placeholder="Например, Если завтра война…"
                icon={faHeading}
                has_error={touched.creation_title && errors.creation_title}
                error={getError("creation_title", { touched, status, errors })}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputWidget
                name="participant_name"
                type="text"
                value={values.participant_name}
                label="Ваше имя"
                placeholder="Например, Иван Петров"
                icon={faUser}
                has_error={touched.participant_name && errors.participant_name}
                error={getError("participant_name", {
                  touched,
                  status,
                  errors,
                })}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputWidget
                name="participant_age"
                type="number"
                value={values.participant_age}
                label="Ваш возраст, лет"
                placeholder="Например, 25"
                icon={faUser}
                has_error={touched.participant_age && errors.participant_age}
                error={getError("participant_age", { touched, status, errors })}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputWidget
                name="participant_country"
                type="text"
                value={values.participant_country}
                label="Ваша страна"
                placeholder="Например, Россия"
                icon={faMapMarkerAlt}
                has_error={
                  touched.participant_country && errors.participant_country
                }
                error={getError("participant_country", {
                  touched,
                  status,
                  errors,
                })}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputWidget
                name="participant_region"
                type="text"
                value={values.participant_region}
                label="Регион вашего проживания"
                placeholder="Например, Москва и Московская область"
                icon={faMapMarkerAlt}
                has_error={
                  touched.participant_region && errors.participant_region
                }
                error={getError("participant_region", {
                  touched,
                  status,
                  errors,
                })}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputWidget
                name="participant_locality"
                type="text"
                value={values.participant_locality}
                label="Ваш населённый пункт"
                placeholder="Например, Москва"
                icon={faMapMarkerAlt}
                has_error={
                  touched.participant_locality && errors.participant_locality
                }
                error={getError("participant_locality", {
                  touched,
                  status,
                  errors,
                })}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputWidget
                name="participant_email"
                type="email"
                value={values.participant_email}
                label="Ваш email"
                placeholder="Например, ivan.petrov@mail.ru"
                icon={faEnvelope}
                has_error={
                  touched.participant_email && errors.participant_email
                }
                error={getError("participant_email", {
                  touched,
                  status,
                  errors,
                })}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextareaWidget
                name="creation_comment"
                type="textarea"
                rows="3"
                value={values.creation_comment}
                label="Напишите нам"
                placeholder="Любые ваши комментарии, например, о ком из воевавших или переживших войну храните память…"
                icon={faHeading}
                has_error={touched.creation_comment && errors.creation_comment}
                error={getError("creation_comment", {
                  touched,
                  status,
                  errors,
                })}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <CheckboxWidget
                name="policy_confirmation"
                value={values.policy_confirmation}
                label={
                  <>
                    Я даю согласие на обработку своих персональных данных и
                    соглашаюсь с{" "}
                    <a href="/policy/" target="_blank">
                      политикой конфиденциальности
                    </a>
                  </>
                }
                has_error={
                  touched.policy_confirmation && errors.policy_confirmation
                }
                error={getError("policy_confirmation", {
                  touched,
                  status,
                  errors,
                })}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Button
                color="danger"
                size="medium"
                type="submit"
                disabled={isSubmitting}
              >
                Отправить
              </Button>
            </form>
          </>
        )
      }}
    </Formik>
  )
}

export default WorkForm
