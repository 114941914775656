import React, { useContext } from "react"
import { Button, Modal, Section } from "rbx"
import { ParticipateContext } from "src/contexts/participate"

export const ParticipateModalButton = props => {
  const { setShowModal } = useContext(ParticipateContext)
  const click = () => {
    setShowModal(true)
    ym(61432513, "reachGoal", "open_form")
    gtag("event", "open_form")
  }
  return (
    <Button onClick={click} {...props}>
      {props.children}
    </Button>
  )
}

export const ParticipateModalLink = props => {
  const { setShowModal } = useContext(ParticipateContext)
  const click = () => {
    setShowModal(true)
    ym(61432513, "reachGoal", "open_form")
    gtag("event", "open_form")
  }
  return (
    <a role="button" tabIndex={0} onClick={click} {...props}>
      {props.children}
    </a>
  )
}

export const CloseParticipateModalButton = props => {
  const { setShowModal } = useContext(ParticipateContext)
  const click = () => {
    setShowModal(false)
  }
  return (
    <Button onClick={click} {...props}>
      {props.children}
    </Button>
  )
}

export const ParticipateModal = props => {
  const { showModal, setShowModal } = useContext(ParticipateContext)
  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <Modal
      id="participate-modal"
      active={showModal}
      closeOnEsc={false}
      onClose={handleClose}
    >
      <Modal.Background />
      <Modal.Content>
        <Section style={{ backgroundColor: "#ffffff" }}>
          {props.children}
        </Section>
      </Modal.Content>
      <Modal.Close />
    </Modal>
  )
}
