import React from "react"

export const Logo = () => (
  <img
    id="logo"
    src="/logo.svg"
    alt="Голос Памяти"
    style={{ height: "100%" }}
  />
)
