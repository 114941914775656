import React from "react"
import { Navbar } from "rbx"
import { Link } from "gatsby"

import { ParticipateModalButton, ParticipateModal } from "src/components/modal"
import WorkForm from "src/components/form"

const Header = props => {
  return (
    <>
      <Navbar color="black">
        <Navbar.Brand>
          <Navbar.Burger />
        </Navbar.Brand>
        <Navbar.Menu>
          <Navbar.Segment align="start">
            <Navbar.Item as="span">
              <a href="/#about-section">Об инициативе</a>
            </Navbar.Item>
            <Navbar.Item as="span">
              <Link to="/works/">Список участников</Link>
            </Navbar.Item>
            <Navbar.Item as="span">
              <Link to="/creations/">Выбор стихов</Link>
            </Navbar.Item>
            <Navbar.Item as="span">
              <Link to="/tips/">Как снять видео</Link>
            </Navbar.Item>
            <Navbar.Item as="span">
              <a href="/#partners-section">Партнёры</a>
            </Navbar.Item>
            {/* <Navbar.Item as="span">
              <a href="/#articles">СМИ о #ГолосПамяти</a>
            </Navbar.Item> */}
          </Navbar.Segment>
          <Navbar.Segment align="end">
            <Navbar.Item as="span">
              <ParticipateModalButton
                color="danger"
                size="medium"
                rounded={true}
              >
                Принять участие
              </ParticipateModalButton>
            </Navbar.Item>
          </Navbar.Segment>
        </Navbar.Menu>
      </Navbar>
      <ParticipateModal>
        <WorkForm />
      </ParticipateModal>
    </>
  )
}

export default Header
