import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery } from "gatsby"
import { Content, Footer as FooterRbx } from "rbx"

const Footer = ({ siteTitle }) => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <FooterRbx>
      <Content className="has-text-centered">
        <p>
          <Link to="/about/">О нас</Link>
          {" | "}
          <Link to="/policy/">Политика конфиденциальности</Link>
        </p>
        <p>
          <a href="mailto:info@golospamyati.ru">info@golospamyati.ru</a>, тел.
          +7 909 677 33 66
        </p>
        <p>
          © {new Date().getFullYear()}, {title}
        </p>
      </Content>
    </FooterRbx>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
}

export default Footer
