/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, author, meta, path }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            lang
            siteUrl
          }
        }
      }
    `
  )
  const { siteMetadata } = data.site

  title = title ? `${title} | ${siteMetadata.title}` : siteMetadata.title
  description = description || siteMetadata.description
  author = author ? author : siteMetadata.author
  const lang = siteMetadata.lang
  const img = `${siteMetadata.siteUrl}/banner.jpg`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: img,
        },
        {
          property: `og:url`,
          content: path
            ? `${siteMetadata.siteUrl}${path}`
            : `${siteMetadata.siteUrl}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: img,
        },
      ].concat(meta)}
    >
      <link rel="icon" href="/icons/icon-48x48.png" type="image/png" />
      <link
        rel="apple-touch-icon"
        href="/icons/icon-48x48.png"
        sizes="48x48"
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        href="/icons/icon-72x72.png"
        sizes="72x72"
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        href="/icons/icon-144x144.png"
        sizes="144x144"
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        href="/icons/icon-192x192.png"
        sizes="192x192"
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        href="/icons/icon-256x256.png"
        sizes="256x256"
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        href="/icons/icon-384x384.png"
        sizes="384x384"
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        href="/icons/icon-512x512.png"
        sizes="512x512"
        type="image/png"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  meta: [],
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  path: PropTypes.string,
}

export default SEO
